<template>
    <v-container fluid>

        
     <!--  <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="newTemplate">New Template</v-btn>
        </v-col>
      </v-row> -->
      
     
      <v-row>
        <v-col cols="12">
          <v-data-table :loading="loading"
            :headers="headers"
            :items="templates"
            item-key="id"
            class="elevation-1"
            mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-toolbar flat class="pl-4">
                <v-toolbar-title>Plantillas E-mail</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="newTemplate" v-if="$store.state.Auth.token.claims.type == 'superuser'">
                    <v-icon left>
                        mdi-plus
                    </v-icon>
                    Agregar 
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn elevation="0" fab x-small  @click="editTemplate(item)">
                    <v-icon>mdi-pencil</v-icon>      
                </v-btn>
                
                <v-btn elevation="0" class="ml-2" fab x-small color="red" @click="deleteTemplate(item.id)" v-if="$store.state.Auth.token.claims.type == 'superuser'">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>


            <template v-slot:item.id="{ item }">{{ trans(item.id) }}</template>

            <template v-slot:item.status="{ item }">
              <v-chip color="green" v-if="item.status">Activo</v-chip>
              <v-chip color="red" v-else>Inactivo</v-chip>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
  
      <v-dialog v-model="dialog" max-width="1000px" persistent>
        <v-card>
          <v-card-title class="pt-0">
            {{ currentTemplate.id ? 'Editar' : 'Nuevo' }} Template
            <v-spacer></v-spacer>

            <v-checkbox v-model="currentTemplate.status" label="Activo"></v-checkbox>
          </v-card-title>
          <v-card-text>
            
            <v-form ref="form">
                <v-text-field hide-details="" filled dense v-if="$store.state.Auth.token.claims.type == 'superuser'" :disabled="edit" v-model="currentTemplate.id" label="ID" :rules="[rules.required]" ></v-text-field>


                <v-text-field hide-details="" filled dense v-model="currentTemplate.title" label="Asunto" :rules="[rules.required]" ></v-text-field>

                <v-combobox dense hide-details="" filled  :search-input.sync="search"  v-model="currentTemplate.replaceVariables" label="Variables de reemplazo"  :items="currentTemplate.replaceVariables" multiple chips>
                    <template v-slot:no-data>
                                <v-list-item @click="addVariable">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                     Presione <kbd>enter</kbd> para agregar.
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template></v-combobox>
            
            
            </v-form>
            <vue-editor v-model="currentTemplate.html"  :editorToolbar="custom"></vue-editor>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="closeDialog" :disabled="loading">Cancelar</v-btn>

            <v-spacer></v-spacer>
            <v-btn color="success"  @click="saveTemplate" :loading="loading"> 
                <v-icon left>
                    mdi-check
                </v-icon>
                Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import { VueEditor } from 'vue2-editor';
  import { collection, getFirestore, onSnapshot, addDoc, doc, updateDoc, deleteDoc, setDoc, getDocs} from 'firebase/firestore';
  
  export default {
    components: {
      VueEditor,
    },
    data() {
      return {
        custom:[
                  [{ header: [false, 1, 2, 3, 4, 5, 6] }],

                ["bold", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
              [{ color: [] }, { background: [] }], 
                ["link"],
                ["clean"],

            ],
       
        search: "",
        loading:true,
        headers: [
          { text: 'Tipo', value: 'id' },
          { text: 'Asunto', value: 'title' },
          { text: 'Estado', value: 'status'},
          { text: 'Acciones', value: 'actions', sortable: false },
          
        ],

        templates: [],
        dialog: false,
        currentTemplate: {
          id: '',
          title: '',
          html: '',
            status:false,
            replaceVariables:[]
        },
        rules: {
          required: value => !!value || 'Required.',
        },
        edit:false,
      };
    },
    methods: {
      fetchTemplates() {
        const db = getFirestore();
        const templatesCol = collection(db, '/configurations/email/templates');
       /*  onSnapshot(templatesCol, (snapshot) => {
          this.templates = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            this.loading = false;
        }); */

        getDocs(templatesCol).then((snapshot) => {
          this.templates = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          this.loading = false;
        });
      },
      newTemplate() {
        this.edit = false;
        this.currentTemplate = { id: null, title: '', html: '', status:false, replaceVariables:[] };
        this.dialog = true;
      },
      editTemplate(template) {
        this.edit = true;
        this.currentTemplate = { ...template };
        this.dialog = true;
      },
      saveTemplate() {
        if (!this.$refs.form.validate()) {
          return;
        }

        this.loading = true;
        const db = getFirestore();
        
        if (this.edit) {
          const templateRef = doc(db, '/configurations/email/templates', this.currentTemplate.id);
          updateDoc(templateRef, {
            title: this.currentTemplate.title,
            html: this.currentTemplate.html,
            status: this.currentTemplate.status,
            replaceVariables: this.currentTemplate.replaceVariables
          }).then(() => {
            this.loading = false;
            this.closeDialog();
            this.$notify({
              type: 'success',
              title: 'Template guardado',
              text: 'Guardado correctamente',
            });
          });


          //update template on list
          this.templates = this.templates.map(template => {
            if (template.id === this.currentTemplate.id) {
              return { ...this.currentTemplate };
            }
            return template;
          });

        } else {
          // Add a new document with custom ID
            setDoc(doc(db, '/configurations/email/templates', this.currentTemplate.id), {
                title: this.currentTemplate.title,
                html: this.currentTemplate.html,
                status: this.currentTemplate.status,
                replaceVariables: this.currentTemplate.replaceVariables
            }).then(() => {

                //add new template to list

                this.templates.push({...this.currentTemplate});

                this.loading = false;
                this.closeDialog();
                this.$notify({
                    type: 'success',
                    title: 'Template guardado',
                    text: 'Guardado correctamente',
                });
            });
        }
      },
      deleteTemplate(id) {
        this.loading = true;
        const db = getFirestore();
        deleteDoc(doc(db, '/configurations/email/templates', id)).then(() => {
            this.templates = this.templates.filter(template => template.id !== id);
            this.loading = false;
            this.$notify({
                type: 'success',
                title: 'Template eliminado',
                text: 'Eliminado correctamente',
            });
        });
      },
      closeDialog() {
        this.dialog = false;
      },
      trans(id) {

        if(id == 'welcome') return "Bienvenida";
        if(id == 'reminder') return "Recordatorio de suscripción";
        if(id == 'expired') return "Renovación de suscripción";
    

        return id;
      },
      addVariable() {
        this.currentTemplate.replaceVariables.push(this.search);
        this.search = null;
      }
    },
    created() {
      this.fetchTemplates();
    },
  };
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  