<template>
  <v-container  fluid>
        
        <SetupMercadoPago/>

    
        <v-dialog v-model="dialog" >
          <v-form @submit.prevent="saveOrUpdateSubscription" ref="form">
            <v-card >
              <v-card-title>
                {{ subscription.id ? 'Editar' : 'Agregar' }} plan suscripción
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-select prepend-inner-icon="mdi-account" v-model="subscription.userType" :items="userTypes" label="Tipo de usuario" item-text="name" item-value="id" filled dense single-line hide-details="" required :rules="[rules.required]"></v-select>
                <v-text-field prepend-inner-icon="mdi-card-text" v-model="subscription.title" label="Titulo" filled dense hide-details="" required :rules="[rules.required]" single-line ></v-text-field>
                <!-- Subscription Matrix Input -->
                <div class="matrix-input">
                  <v-simple-table fixed-header>
                    <thead>
                      <tr>
                        <th class="text-left">Meses</th>
                        <th class="text-left" v-for="(day, index) in days" :key="'day-header-' + index">{{ day }} 
                        {{ day<=6? (day>1 ? 'Días' : 'Día'):'' }}
                        </th>
                        <th>
                            Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, rowIndex) in subscription.priceMatrix" :key="'row-' + rowIndex">
                        <td >
                            <v-text-field style="min-width:80px" placeholder="# MESES" v-model="row[0].months" hide-details="" dense single-line filled rounded type="number"></v-text-field>
                            <!-- {{ rowIndex>0 ? 'Meses' : 'Mes' }} -->
                        </td>
                        <td v-for="(cell, cellIndex) in row" v-if="cellIndex>0" >
                          <v-text-field filled rounded v-model="cell.price" placeholder="$" dense hide-details="" style="min-width:90px" single-line></v-text-field>
                        </td>
                        <td>
                            <v-btn elevation="0" color="error" fab dark x-small @click="subscription.priceMatrix.splice(rowIndex, 1)"> 
                                <v-icon >mdi-close</v-icon>
                                </v-btn>
                        </td>
                      </tr>
                                                        <v-btn   small class="ma-2" @click="addMonth">
                                                            <v-icon left>
                                                                mdi-plus
                                                            </v-icon>
                                                            Añadir Mes</v-btn>

                    </tbody>
                  </v-simple-table>

                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn type="submit" color="success" :loading="loading">
                  <v-icon left>{{ subscription.id ? 'mdi-pencil' : 'mdi-plus' }}</v-icon>
                  {{ subscription.id ? 'Actualizar' : 'Agregar' }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <v-data-table :loading="loading" :search="search" :headers="headers" :items="subscriptions" class="elevation-1" mobile-breakpoint="0" @click:row="editSubscription" disable-pagination hide-default-footer>
          <template v-slot:top>
              <v-toolbar flat class="pl-4">
                <v-toolbar-title>Suscripciones</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="addNew" color="success">
                  <v-icon left>mdi-plus</v-icon>
                  Nuevo
                </v-btn>
              </v-toolbar>
              <v-text-field dense hide-details="" class="rounded-0" single-line filled v-model="search" label="Buscar" prepend-inner-icon="mdi-magnify"></v-text-field>

            </template>
          
          
          
          
          
          <template v-slot:item.userType="{ item }">
                     <v-chip  
                        :color="getColor(item.userType)"
                        dark > 
                      {{ item.userType ? item.userType.toUpperCase() : '' }}
                      </v-chip>
                  </template>
            <template v-slot:item.subscriptionMatrix="{ item }">
  <div>

    <v-simple-table  dense class="pa-2 my-2">
        
      <thead>
        <tr>
          <th>Meses</th>
          <th v-for="(day, index) in days" :key="'day-header-' + index" v-if="checkIfDisplay(item,day,index)">{{ day }}
            {{ day>0 && day<=6 ? (day>1 ? 'Días' : 'Día') : '' }}
        
        </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in item.priceMatrix" :key="'row-' + rowIndex">
          <td>{{ row[0].months }}</td>
          <td v-for="(cell, cellIndex) in row.slice(1)" :key="'cell-' + cellIndex" v-if="cell.price>0">
            ${{ cell.price }} 
            <span v-if="rowIndex>0 && cell.price && cell.price>0">
        <!--      Precio sin descuento {{ (item.priceMatrix[0][cellIndex+1].price * row[0].months )}} 
             Descuento total: {{  cell.price - (item.priceMatrix[0][cellIndex+1].price * row[0].months) }} -->

             <v-chip color="primary darken-2" small class="ml-1">
            -{{ (100 - (cell.price / (item.priceMatrix[0][cellIndex+1].price * row[0].months) * 100)).toFixed(2) }}%
            </v-chip>
            </span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>





  </div>
</template>

            <template v-slot:item.actions="{ item }">
                <v-btn elevation="0" color="error" fab x-small @click.stop="deleteSubscription(item)">
              <v-icon >mdi-delete</v-icon>

                </v-btn>
            </template>


        </v-data-table>
   
  </v-container>
</template>
<script>
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc, getFirestore } from 'firebase/firestore';
import SetupMercadoPago from '@/components/checkout/SetupMercadoPago.vue';


export default {
  components: {
    SetupMercadoPago
  },
  name: 'SubscriptionManager',
  data() {
    return {
      showClientSecret: false,
      showAccessToken: false,
      mercadoPagoConfiguration: {},
      mercadoPagoConfigurationDialog:false,
      subscription: this.defaultSubscription(),
      subscriptions: [],
      userTypes: [
        { id: 'usuario', name: 'Usuario' },
        { id: 'representante', name: 'Representante' },
        { id: 'opengym', name: 'Open Gym' },
        { id: 'online', name: 'Online' }
      ],
      search: '',
      dialog: false,
      loading: true,
      rules: {
        required: value => !!value || 'Requerido.',
      },
      headers: [
        { text: 'Tipo de usuario', value: 'userType' },
          { text: 'Titulo', value: 'title' },
          { text: 'Plan', value: 'subscriptionMatrix' }, // New column for subscription matrix

        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      days: [1, 2, 3, 4, 5, 6, 'Libre'],
    };
  },
    methods: {

    defaultSubscription() {
      return {
        title: '',
        userType: '',
        priceMatrix: [
          [
            { months: 1 },
            { price: null }, { price: null },  { price: null }, { price: null }, { price: null }, { price: null }, { price: null }
          ]
        ],
      };
    },
    addDay() {
      this.days.push(this.days.length + 1); // Adjust based on your requirements
      this.subscription.priceMatrix.forEach(row => row.push({ price: '' }));
    },
    addMonth() {
      this.subscription.priceMatrix.push([{ months: this.subscription.priceMatrix.length + 1 }, ...this.days.map(() => ({ price: null }))]);
    },
    prepareForFirestore(subscription) {
      const serializedMatrix = subscription.priceMatrix.map(row => ({
        months: row[0].months,
        prices: row.slice(1).map(cell => cell.price || '0'), // Use '0' or a suitable default for empty prices
      }));
      // Filter out any fields that are undefined
      const cleanedSubscription = Object.entries(subscription).reduce((acc, [key, value]) => {
        if (value !== undefined) acc[key] = value;
        return acc;
      }, {});
      return { ...cleanedSubscription, priceMatrix: serializedMatrix };
    },
    fromFirestore(serializedSubscription) {
      const deserializedMatrix = serializedSubscription.priceMatrix.map(row => [
        { months: row.months },
        ...row.prices.map(price => ({ price }))
      ]);
      return { ...serializedSubscription, priceMatrix: deserializedMatrix };
    },
    async saveOrUpdateSubscription() {
      this.loading = true;
      const db = getFirestore();
        let subscriptionToSave = this.prepareForFirestore(this.subscription)
      if (this.subscription.id) {
        await updateDoc(doc(db, "subscriptions", this.subscription.id), subscriptionToSave);
      } else {
        await addDoc(collection(db, "subscriptions"), subscriptionToSave);
      }
      await this.fetchSubscriptions();
      this.resetForm();
    },
    async fetchSubscriptions() {
      this.loading = true;
      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, "subscriptions"));
      this.subscriptions = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return { ...this.fromFirestore(data), id: doc.id };
      });
      this.dialog = false;
      this.loading = false;
    },
    resetForm() {
      this.subscription = this.defaultSubscription();
      this.dialog = false;
    },
    editSubscription(item) {
      this.subscription = { ...item };
      this.dialog = true;
    },
    checkIfDisplay(item,day,index){
        // iterate the price matrix and check if there is a price value for the given day return true
        return item.priceMatrix.some(row => row[index+1].price > 0);
    },
    getColor(type) {
      switch (type) {
        case 'superuser':
          return 'black'
        case 'admin':
          return 'orange'
        case 'usuario':
          return 'green darken-3';
        case 'entrenador':
          return 'blue';
        case 'paselibre':
          return 'cyan';
        case 'gratis':
          return 'green darken-2';
        default:
          return 'grey'
      }
        },
        async deleteSubscription(item) {

            let confirm = await this.$confirm( "¿Está seguro que desea eliminar este plan de suscripción?",
                     {
            color: "error",
            title: "Eliminar Plan",
            icon: "mdi-alert-circle",
            buttonTrueText: "Si",

                }
            );

            if(!confirm){
                return;
            }
                

            this.loading = true;
            const db = getFirestore();
            deleteDoc(doc(db, "subscriptions", item.id));
            this.fetchSubscriptions();
        },
        addNew() {
            this.resetForm();
            this.dialog = true;
        }
  },
  mounted() {
    this.fetchSubscriptions();
  },
};
</script>


<style>
/* Style adjustments as necessary */
</style>
