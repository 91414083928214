<template>
  <v-container class="pa-0 ">
    <v-tabs :vertical="!$vuetify.breakpoint.smAndDown" :show-arrows="$vuetify.breakpoint.smAndDown"
      :grow="$vuetify.breakpoint.smAndDown">

      <v-tab>
        <v-icon left>
          mdi-account-group
        </v-icon>
        Planes
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-calendar-month
        </v-icon>

        Agenda
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-email
        </v-icon>
        E-mail
      </v-tab>

      <v-tab-item>
        <Subscriptions />
      </v-tab-item>
      <v-tab-item>
        <Schedules />
      </v-tab-item>
      <v-tab-item>
        <!--         <AlertTemplates/>
 -->
        <EmailTemplates />
      </v-tab-item>
    </v-tabs>


  </v-container>

</template>

<script>
import Schedules from '@/components/Schedules.vue';

import EmailTemplates from '@/components/templates/EmailTemplates.vue';
import AlertTemplates from '@/components/templates/AlertTemplates.vue';

import Subscriptions from '@/views/Subscriptions.vue';
export default {
  components: {
    Schedules,
    Subscriptions,
    EmailTemplates,
    AlertTemplates
  },
  data() {
    return {
      //
    }
  }
}
</script>